import React from "react";
import { Link } from "gatsby";
import darkLogo from "../../assets/passnip-logo-white.svg";

export default function Footer() {
  return (
    <div className="bg-white p-2 pt-0 flex flex-col relative">
      <div className="bg-dark rounded-lg sm:rounded-2xl">
        <Link to="/#top">
          <img src={darkLogo} className="w-32 m-auto mt-8" alt="Passnip logo" />
        </Link>
        <p className="text-center text-xs font-mono pb-8 sm:pb-0 text-white my-12">
          &copy;2020 Passnip.&nbsp;
          <a href="https://hachi.studio" className="underline hover:opacity-75" rel="noreferrer" target="_blank">Built by Hachi</a>
        </p>
        <hr className="mb-2 border-gray-800" />
        <div className="flex items-center justify-center text-gray-600 m-auto max-w-2xl mono-font text-xs mb-8 p-4">
          <div className="mx-2 underline">
            <Link to="/#top">
              Home
            </Link>
          </div>
          <div className="mx-2 underline">
            <Link to="/terms">
              Terms
            </Link>
          </div>
          <div className="mx-2 underline">
            <Link to="/privacy">
              Privacy
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
