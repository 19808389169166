import React from "react";
import { Link } from "gatsby";
import Cookies from 'universal-cookie';
import logo from "../../assets/passnip-logo.svg";
import darkLogo from "../../assets/passnip-logo-white.svg";

export default function Nav({dark}) {
  const cookies = new Cookies();
  const hidePing = cookies.get('passnip_about_visited');
  return (
    <nav className="flex items-center justify-between flex-wrap p-6">
      <span className="font-semibold text-xl tracking-tight">
        <Link to="/">
          <img src={dark ? darkLogo : logo} alt="Passnip" className="w-32" />
        </Link>
      </span>
      <a className="ml-2 block w-32 sm:w-48" href="https://www.producthunt.com/posts/passnip?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-passnip" target="_blank">
        <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=276424&theme=dark" alt="Passnip - Secure passwords that you'll remember. | Product Hunt"/>
      </a>
    </nav>
  )
}
