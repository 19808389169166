import React, { useRef, useState } from 'react';
import wordice from "wordice";
import wordsList from "./wordsList";

export default function Nav() {

  const passnipWordParams = {
    wordCount: 4,
    MinCharacters: 5,
    MaxCharacters: 9,
    join: '-',
  };
  const [passnipRandomNum, setPassnipRandomNum] = useState(Math.floor(Math.random() * (9999 - 1000+1)+1000));
  const [passnipRandomWords, setPassnipRandomWords] = useState(wordice(wordsList, passnipWordParams));
  const [addNum, setAddNum] = useState(false);
  const [capitalize, setCapitalize] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const [animation, setAnimation] = useState();

  const textAreaRef = useRef(null);
  const delay = ms => new Promise(res => setTimeout(res, ms));
  const generateRandomWords = async(r) => {
    setAnimation('-translate-y-2 opacity-0');
    await delay(200);
    setAnimation('translate-y-2 opacity-0');
    await delay(200);
    setPassnipRandomWords(wordice(wordsList, passnipWordParams));
    setAnimation('');
    setPassnipRandomNum((Math.floor(Math.random() * (9999 - 1000+1)+1000)));
  }

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand('copy');
    // This is just personal preference.
    // I prefer to not show the whole text area selected.
    setCopySuccess('Copied!');
    setCopySuccess(true);
    setTimeout(
    () => {
    setCopySuccess(false);
  }, 4000);
  };

  function closeAlert() {
    setCopySuccess(false);
  }

  return (
    <>
      <form className="w-full">
        <div className="passnip-search bg-white bg-opacity-50 border-t border-white hover:bg-opacity-10 relative text-xs md:text-lg flex items-center rounded-lg px-2 py-4 md:px-4 mb-4 mx-2 shadow-md hover:shadow-lg transition ease-in duration-200">
            <input
              readOnly
              className={`${animation} font-bold transition duration-200 ease-in-out transform text-center appearance-none bg-transparent border-none w-full text-gray-800 mr-3 py-1 px-2 leading-tight focus:outline-none mono-font`}
              type="text"
              value={addNum ? capitalize ? passnipRandomWords  + '-' + passnipRandomNum : passnipRandomWords.toLowerCase()  + '-' + passnipRandomNum  : capitalize ? passnipRandomWords : passnipRandomWords.toLowerCase() }
              aria-label="Full name"
              ref={textAreaRef}
            />
          <button
            onClick={() => generateRandomWords()}
            className="flex-shrink-0 border-transparent border-4 text-dark hover:text-gray-600 active:text-gray-200 text-sm py-1 px-2 rounded focus:outline-none"
            type="button"
          >
            <svg viewBox="0 0 20 20" fill="currentColor" className="refresh w-4 h-4 sm:w-6 sm:h-6"><path fillRule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z" clipRule="evenodd"></path></svg>
          </button>
          <button
            onClick={() => copyToClipboard()}
            className="flex-shrink-0 border-transparent border-4 text-dark hover:text-gray-600 active:text-gray-200 text-sm py-1 px-2 rounded focus:outline-none"
            type="button"
          >
            <svg viewBox="0 0 20 20" fill="currentColor" className="clipboard-check w-4 h-4 sm:w-6 sm:h-6"><path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"></path><path fillRule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm9.707 5.707a1 1 0 00-1.414-1.414L9 12.586l-1.293-1.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
          </button>
          <div
            style={{ top: -20, right: 50}}
            className={`bg-black text-xs text-white px-3 py-2 rounded absolute transition duration-200 ease-in-out transform scale-50 ${copySuccess ? 'opacity-100 -translate-y-1 scale-110' : 'opacity-0'}`} role="alert"
          >
            <strong className="font-bold mono-font uppercase">COPIED TO CLIPBOARD</strong>
          </div>
        </div>
        <div className="flex">
          <div className="ml-2 mb-6">
            <label className="block text-black font-bold mono-font relative cursor-pointer hover:opacity-75">
              <input
                className="w-0 leading-tight invisible"
                type="checkbox"
                onClick={() => {
                setAddNum(!addNum);
              }}
              />
              <span className={`mr-2 relative custom-checkbox text-white w-4 h-4 inline-block absolute left-0 mt-1 rounded-sm border-2 shadow-sm  ${addNum ? 'bg-black border-black' : 'border-white bg-white bg-opacity-50'}`} style={{ top: 2 }}>
                <svg viewBox="0 0 20 20" fill="currentColor" style={{ left: -1, top: -1 }} className="relative w-3 h-3"><path fillRule="evenodd" d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" clipRule="evenodd"></path></svg>
              </span>
              <span className="text-sm">
                Add numbers
              </span>
            </label>
          </div>
          <div className="ml-4 mb-6">
            <label className="block text-black font-bold mono-font relative cursor-pointer hover:opacity-75">
              <input
                className="w-0 leading-tight invisible"
                type="checkbox"
                onClick={() => {
                setCapitalize(!capitalize);
              }}
              />
              <span className={`mr-2 relative custom-checkbox text-white w-4 h-4 inline-block absolute left-0 mt-1 rounded-sm border-2 shadow-sm  ${capitalize ? 'bg-black border-black' : 'border-white bg-white bg-opacity-50'}`} style={{ top: 2 }}>
                <svg viewBox="0 0 20 20" fill="currentColor" style={{ left: -1, top: -1 }} className="relative w-3 h-3"><path fillRule="evenodd" d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" clipRule="evenodd"></path></svg>
              </span>
              <span className="text-sm">
                Capitalize
              </span>
            </label>
          </div>
        </div>
      </form>
    </>
  )
}
